import React from 'react';
import ReactDOM  from 'react-dom/client';
import './index.css';
import './classic.scss';
import App from './App';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from "./reportWebVitals";


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>    
    <Main />
  </React.StrictMode>
);

reportWebVitals();



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
