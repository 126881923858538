
const chatgptUrl = 'https://api.openai.com/v1/chat/completions';
const symblAppId = '6d57415171386b3230325a49475a5741774f765059494b456a43467a7a364250';
const symblAppSecret = '544a64756365576132444665664b78636e6c665f544a4e62507a505469595a61395a69375a4d36684d45654d7751515f356b414c534d714c4470365970437344';
const symblApiBasePath = "https://api.symbl.ai";

export const openAICall = async (chatMessages: any) => {
    const cc = chatMessages;
    const API_KEY = "sk-svcacct-z_i5uHvqNl9NkCT1jOD4GQH56dOsQhDWulyazYSSTHu8L3T3BlbkFJ8YCZgFlOVZDhomBsdNEa7FNO5R4vpmWVZ13VRL1rCzFnIA"
    let apiMessages = chatMessages.map((messageObject: any) => {
        let role = "";
        if (messageObject.sender === "ChatGPT") {
            role = "assistant"
        } else {
            role = "user"
        }
        return (
            { role: "user", content: messageObject.message }
        )
    });

    const systemMessage = {
        role: "system",
        content:
            "You are an assistant,Provide detailed, consistent answers,Always use bullet points or numbered lists for clarity where applicable,Maintain a neutral, professional tone, and ensure responses are easy to understand."
        // "You are an assistant that provides clear, concise, and consistent summaries. Please ensure that you summarize the key points and eliminate unnecessary details."
        //"You are an assistant that provides detailed, structured, and comprehensive summaries. Ensure that the summary includes key points, work updates, and important actions. The summary should be well-structured and organized into bullet points where possible."
    }

    const apiRequestBody = {
        "model": "gpt-4",
        "messages": [
            systemMessage,
            ...apiMessages,
        ],
        max_tokens: 1000,
        temperature: 0.2,
        top_p: 0.9,
        frequency_penalty: 0,
        presence_penalty: 0,
    }

    return await fetch(chatgptUrl, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${API_KEY}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
    }).then((response) => {
        return response.json();
    }).then((data) => {
        console.log("#choices",data);
        if (data.choices && data.choices.length > 0)
            return Promise.resolve(data.choices[0].message.content);
        else if(data.error)
         return Promise.resolve(data.error.message);
        else 
            return Promise.resolve('sorry, some thing went wrong, please try again');

    }).catch(error => {
        console.log("#choices",error);
    })
}

export const SymblUICall = async (token: any) => {


    // return await fetch('https://api.symbl.ai/v1/conversations/experiences/insights/list?includeCallScore=tru',{
    return await fetch('https://api.symbl.ai/v1/conversations/experiences/insights/details/4518471026868224', {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        // body: JSON.stringify(apiRequestBody)
    }).then((response) => {
        return response.json();
    })
        .then((data) => {
            console.log(data);
            return Promise.resolve(data);

        })
}
export const SymblUI = async (token: any) => {


    return await fetch('https://insights-ui.symbl.ai/meeting-details/4518471026868224?o=' + token + '&conversationType=general&includeCallScore=false', {
        method: "GET"
    }).then((response) => {
        return response.json();
    })
        .then((data) => {
            console.log(data.choices[0].message.content);
            return Promise.resolve(data.choices[0].message.content);

        })
}

export const symblToken = async () => {
    try {
        const response = await fetch(
            `${symblApiBasePath}/oauth2/token:generate`,
            {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    type: "application",
                    appId: symblAppId,
                    appSecret: symblAppSecret
                })
            }
        );
        // res.setHeader("Content-Type", "application/json");
        return await response.json();
    } catch (e) {
        console.error("Error while issuing Symbl Token.", e);
    }
}