import EventEmitter from 'events';
import { Dispatch } from "redux";
import { getRequest } from "../utils/http";
import * as T from "../actions/Types";

const api_domain = process.env.REACT_APP_API_HOST as string;

export class MediaService extends EventEmitter {
    public imageUrl: string;
    constructor() {
        super()
        this.imageUrl = "";
    }
}

export const SetMeetingId = (meetingId:any) => async (dispatch: Dispatch) => {
    dispatch({
        type: T.MEETINID_SET,
        payload: meetingId
    });
}

export const GetMediaTracksList = () => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetMediaContent`)
            .then((resp: any) => {
                dispatch({
                    type: T.MEDIA_TRACK_LIST_SUCCESS,
                    payload: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.MEDIA_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}


export const GetSoundBathTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.SOUND_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SOUND_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetLeaderShipTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.LEADER_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.LEADER_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetAnxietySlayerTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.ANXIETY_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.ANXIETY_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetDiversityTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.DIVERSITY_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.DIVERSITY_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetLifeBalanceTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.LIFE_BALANCE_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.LIFE_BALANCE_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetGuidedMeditationTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.GUIDED_MEDITATION_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GUIDED_MEDITATION_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetPersonalGrowthTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.PERSONAL_GROWTH_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.PERSONAL_GROWTH_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetPilanthropyTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.PILANTHROPY_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.PILANTHROPY_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetMentalHealthTracksList = (MediaTypeId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetTracksList?MediaTypeId=${MediaTypeId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.MENTALHEALTH_TRACK_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.MENTALHEALTH_TRACK_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetProfileAvatarsList = (type: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domain}/media/GetProfileAvatars?type=${type}`)
            .then((resp: any) => {
                dispatch({
                    type: T.PROFILE_AVATAR_LIST_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.PROFILE_AVATAR_LIST_FAILURE,
                    payload: e
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const mediaService = new MediaService();