import { Dispatch } from "redux";
import { postRequest, getRequest } from "../utils/http";
import * as T from "../actions/Types";


const api_domian = process.env.REACT_APP_API_HOST as string;
const google_redirect_uri = process.env.REACT_APP_URL as string;

export const getEmployeeCalanderEvents = (empGuid: any,OffSet : number) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetCalendarEventsByEmp?empGuid=` + empGuid + `&OffSet=` +OffSet)
            .then((resp: any) => {
                let result;
                console.log(" ------------- EVENTS --------",resp)
                if(resp.result.length > 0) {
                    const data = resp.result?.map((element: any) => {
                        if(element.Checked && element.Email.includes('@')) {
                            element.Checked = false
                            return element;
                        }
                        return element;
                    });
                    result = {...resp, result: data}
                }
                dispatch({
                    type: T.GET_EMPLOYEE_EVENTS,
                    data: result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const CheckUncheckEventsEmail = (email: string, checked: boolean) => async (dispatch: Dispatch) => {
    dispatch({
        type: T.CHECK_UNCHECK_EVENT_EMAIL,
        data: { Email: email, Checked: checked }
    })
}

export const CheckUncheckEventCategories = (categoryId: string, checked: boolean) => async (dispatch: Dispatch) => {
    //  
    dispatch({
        type: T.CHECK_UNCHECK_EVENT_CATEGORY,
        data: { CategoryId: categoryId, Checked: checked }
    })
}

export const SyncGoogleCalanderEvents = (code: String, empGuid: string) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/SyncGoogleCalendarEvents?code=` + code + `&redirect_uri=` + google_redirect_uri + `&empGuid=` + empGuid)
            .then((resp: any) => {
                dispatch({
                    type: T.SYNC_GOOGLE_CALENDER,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const SyncExchangeCalendarEvents = (accessToken: string, email: string, name: string, empGuid: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/SyncExchangeCalendarEvents?accessToken=` + accessToken + `&email=` + email + `&name=` + name + `&empGuid=` + empGuid)
            .then((resp: any) => {
                dispatch({
                    type: T.SYNC_EXCHANGE_CALENDER,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const SaveGoogleCalenderEvents = (params: any) => async (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/Teams/CreateEvent`, params)
            .then((resp: any) => {
                // 
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'Event Created'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        });
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
};

export const DeleteCalenderEventByEventId = (eventId: string, empId: string, empEmail: string) => async (dispatch: Dispatch) => {
    // 
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/DeleteEventByEventId?eventId=` + eventId + `&empId=` + empId + `&email=` + empEmail)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'Event Deleted'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        })
    }
};

export const UpdateGoogleCalenderEvents = (params: any) => async (dispatch: Dispatch) => {
    // 
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/Teams/EditEvent`, params)
            .then((resp: any) => {
                //  
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'Event Updated'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                })
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        })
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
};

export const getCalanderEventCategories = () => async (dispatch: Dispatch) => {
    //  
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetEventCategories`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.CALENDER_EVENT_CATEGORIES,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const removeSuccessStatus = () => async (dispatch: Dispatch) => {
    dispatch({
        type: T.REMOVE_CAL_SUCCESS
    });
}

export const BookReminderForContct = (params: any) => async (dispatch: Dispatch) => {
    //  
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/Teams/BookReminderForContct`, params)
            .then((resp: any) => {
                //  ;
                dispatch({
                    type: T.INTERNAL_EVENT_CREATE_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const GetTimeslots = (employeeGuid: string, selectedDate: string) => async (dispatch: Dispatch) => {
    //  
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetTimeslots?SelectedDate=` + selectedDate + `&EmployeeGuid=` + employeeGuid)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.AVAILABLE_TIME_SLOTS_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const UpdateCalendarEventResponse = (value: any) => async (dispatch: Dispatch) => {
    // 
    if (value == 'updateEditEventResponse') {
        dispatch({
            type: T.CALENDAR_EVENT_UPDATE_SUCCESS
        })
    }
    if (value == 'updateCreateEventResponse') {
        dispatch({
            type: T.SAVE_GOOGLE_CALENDAR_EVENT_SUCCESS
        })
    }
    if (value == 'updateDeleteAccountResponse') {
        dispatch({
            type: T.DELETE_CALENDAR_ACCOUNT_SUCCESS
        })
    }
    if (value == 'updatesyncgooglecalendarresponse') {
        dispatch({
            type: T.SYNC_GOOGLE_CALENDER
        })
    }

}

export const DeleteCalenderAccountByAccountId = (accountId: number) => async (dispatch: Dispatch) => {
    // 
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/DeleteCalendarAccount?accountId=` + accountId)
            .then((resp: any) => {
                dispatch({
                    type: T.DELETE_CALENDAR_ACCOUNT_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.DELETE_CALENDAR_ACCOUNT_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.DELETE_CALENDAR_ACCOUNT_FAILURE
        })
    }
}

export const getEventParticipantsByEventId = (eventId: string) => (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetParticipantsById?eventId=` + eventId)
            .then((resp: any) => {
                console.log(" ========================================== EVENT_PARTICIPANTS ====================",resp);
                dispatch({
                    type: T.EVENT_PARTICIPANTS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        });
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
}

export const getEmployeeCalanderEmails = (empGuid: any) => async (dispatch: Dispatch) => {
    // 
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetCalenderEmailsByEmpGuid?empGuid=` + empGuid)
            .then((resp: any) => {
                dispatch({
                    type: T.GET_EMPLOYEE_CALENDAR_EMAILS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
}

export const removeEventParticipants = () => async (dispatch: Dispatch) => {
    dispatch({
        type: T.EVENT_PARTICIPANTS
    });
}

export const GetGroupTimeslots = (groupGuid: string, selectedDate: string) => async (dispatch: Dispatch) => {
    //  
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetGroupTimeslots?SelectedDate=` + selectedDate + `&GroupGuid=` + groupGuid)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.AVAILABLE_TIME_SLOTS_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const BookEventForGroup = (params: any) => async (dispatch: Dispatch) => {
    //  
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/Teams/BookEventForGroup`, params)
            .then((resp: any) => {
                //  ;
                dispatch({
                    type: T.INTERNAL_EVENT_CREATE_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

export const getGroupEventParticipantsByEventId = (eventId: string) => (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetGroupEventParticipantsById?eventId=` + eventId)
            .then((resp: any) => {

                dispatch({
                    type: T.EVENT_PARTICIPANTS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        });
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
}

export const GetGroupEventsByDate = (Date: string, GroupGuid: string , empId : any) => (dispatch: Dispatch) => {
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/GetGroupEventsByDate?Date=` + Date + `&GroupGuid=` + GroupGuid + `&empGuid=` +empId )
            .then((resp: any) => {

                dispatch({
                    type: T.GROUP_EVENTS_SUCCESS,
                    payload: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        });
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
}

export const DeleteGroupEventByEventId = (eventId: string) => async (dispatch: Dispatch) => {
    // 
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/Teams/DeleteGroupEventByEventId?eventId=` + eventId)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'Event Deleted'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        })
    }
};

export const GetCalendarattachements = async(eventGuid:any) => {
     try {
         
     return   getRequest(`${api_domian}/Teams/GetCalendarattachements?eventGuid=`+eventGuid)
        .then((resp: any)=>{
             return resp
         }).catch(e=>{
             console.log(e);
             
                 return "ERROR";   
         });
     } catch (e) {
         console.log(e);
         
         return "ERROR";
     }
  }

  export const GetEmployeeAvalibilty = async(Emails:any , UTCStartTime :any ,UTCEndTime :any , GroupGuid :any , IsAllDay:any, OrgGuid : any) => {
    try {
        
    return   getRequest(`${api_domian}/Teams/GetEmployeeAvalibilty?Emails=${Emails}&UTCStartTime=${UTCStartTime}&UTCEndTime=${UTCEndTime}&GroupGuid=${GroupGuid}&IsAllDay=${IsAllDay}&OrgGuid=${OrgGuid}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
    }
 }
 export const DeleteRecuringEvent = (params: any) => async (dispatch: Dispatch) => {
    // 
    try {
        //const domain = 'https://localhost';
        //const port = '8461';
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/Teams/DeleteRecuringEvent`, params)
            .then((resp: any) => {
                //  
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'Event Updated'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                })
                dispatch({
                    type: T.REMOVE_LOADING
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        })
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
};

export const GetEventPartcipants =async (eventId:any ,emp_id:any, date:any) => {
    try {
        return getRequest(`${api_domian}/Teams/GetEventPartcipants?event_id=${eventId}&emp_id=${emp_id}&date=${date}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
                return "ERROR";
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }

 export const GetAllDaySingleEvent =async (eventId:any) => {
    
    try {
        return getRequest(`${api_domian}/Teams/GetAllDaySingleEvent?event_id=${eventId}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
                return "ERROR";
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }




