import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';  
import { logger } from 'redux-logger'
import AllReducers from '../reducers';
import {initializeNetwork} from '../actions/MsActions';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) {
      return undefined;
    }else {
      const pstate = JSON.parse(serializedState);
      return pstate;
    };
  } catch(err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch(err) {
    console.log(err);
  }
};
//const persistedState = loadState();

const Store = createStore(
    AllReducers,  
    applyMiddleware(thunk)
  );
export type StoreClass = ReturnType<typeof AllReducers>
//Store.dispatch(initializeNetwork());
/*Store.subscribe(() => {
  saveState(Store.getState());
});*/
export default Store;
