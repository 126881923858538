import { Dispatch } from "redux";
import {postRequest, getRequest} from "../utils/http";
import * as T  from "../actions/Types";
const api_domian = process.env.REACT_APP_API_HOST as string;
 
export const createGroup = (params: any) => async (dispatch: Dispatch) => {
    
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/teams/SaveGroupWithEmp`, params)
       .then((resp: any)=>{
           const resStr = resp as string;
           if(resStr && resStr.indexOf('Group created')>-1){
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "SaveGroupWithEmp"
                }); 
            }else{
                dispatch({
                    type: T.CREATE_ROOM_FAILURE,
                    payload: resStr
                });  
            }    
            dispatch({
                type: T.REMOVE_LOADING
            })    
        }).catch(e=>{
            dispatch({
                type: T.CREATE_ROOM_FAILURE,
                payload: e
            })
        });   
 }
export const getGroupMems = (groupId: any) => async (dispatch: Dispatch) => {
    try {
        console.log(groupId);
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}/teams/GetEmployeeListByGroup?grpGuid=` + groupId)
       .then((resp: any)=>{
           // ;
            dispatch({
                type: T.GET_TEAM_MEMBERS,
                contacts: resp.result
            });
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }

 export const getGroupMemsAsync = async (groupId: any) =>  {
    return getRequest(`${api_domian}/teams/GetEmployeeListByGroupSession?grpGuid=` + groupId)
    .then((resp: any)=>{
        if(resp.status === 400)
            return [];
        //console.log('GetEmployeeListByGroupSession', resp);
        return resp.result;
    }).catch(e=>{
        console.log(e);
        return [];
    });
 }

export const getGroupsByEmpId = (empId: any) => async (dispatch: Dispatch) => {
    
    try {
        //console.log(empId);
        //dispatch({ type: T.ADD_LOADING })
        if(!empId)
        return;
        getRequest(`${api_domian}/teams/GetGroupsListByEmp?empGuid=` + empId)
       .then((resp: any)=>{
            dispatch({
                type: T.GET_TEAMS_SUCCESS,
                groups: resp
            });
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }

 /*** update Group with params- GroupGuid,
GroupName,
Description,
Type,
Color */
export const updateGroup = (params: any) => async (dispatch: Dispatch) => {
    
    
    try {
        //console.log(params);
        dispatch({ type: T.ADD_LOADING });
        postRequest(`${api_domian}/teams/UpdateGroup`, params)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "UpdateGroup"
                });
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS,
                    payload: e
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
}

/**get all Groups by org orgGuid*/
 export const GetGroupsListByOrg = (orgGuid: any) => async (dispatch: Dispatch) => {
    try {
        //console.log(empId);
        //dispatch({ type: T.ADD_LOADING })
        getRequest(`${api_domian}/teams/GetGroupsListByOrg?orgGuid=` + orgGuid)
       .then((resp: any)=>{
            dispatch({
                type: T.GET_TEAMS_SUCCESS,
                groups: resp
            });
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }
 export const AddPreferredContact = (params: any) => async (dispatch: Dispatch) => {
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/employee/AddPreferredContact`, params)
       .then((resp: any)=>{
           // ;
            dispatch({
                type: T.SAVE_SUCCESS,
                success: "AddPreferredContact"
            });
            //make isPrefered =true for 'empGuid" in contacts of company directory
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }
 export const removeSuccessStatus = ()=> async (dispatch: Dispatch) => {
    dispatch({
        type: T.REMOVE_SUCCESS
    });
 }
 export const removePreferredContact = (params: any, empId:string) => async (dispatch: Dispatch) => {
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/employee/RemovePreferredContact`, params)
       .then((resp: any)=>{
           // ;
            dispatch({
                type: T.SAVE_SUCCESS,
                success: "RemovedPreferredContact"
            });
            GetPreferredContacts(empId);
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }

 export const GetPreferredContacts = (empId: any) => async (dispatch: Dispatch) => {
    try {
       // console.log(params);
        // dispatch({
        //     type: T.ADD_LOADING
        // })
        getRequest(`${api_domian}/employee/GetPreferredContacts?empGuid=${empId}`)
       .then((resp: any)=>{
           // ;
            dispatch({
                type: T.PREF_CONTACTS_SUCCESS,
                contacts: resp.result
            });
            // dispatch({
            //     type: T.REMOVE_LOADING
            // })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }
 
 export const removeFromGroup = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: T.ADD_LOADING})

        postRequest(`${api_domian}/teams/RemoveMemberFromGroup`, params)
       .then((resp: any)=>{
            dispatch({
                type: T.SAVE_SUCCESS,
                success: "RemoveMemberFromGroup"
            });
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({type: T.SET_ERRORS,payload: e});
        });
    } catch (e) {
        dispatch({type: T.SET_ERRORS,payload: e});
    }
 }

export const AddMemberToGroup = (params: FormData) => async (dispatch: Dispatch) => {
    try {
        console.log(params);
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}/teams/AddMemberToGroup`, params as any)
       .then((resp: any)=>{

            dispatch({
                type: T.SAVE_SUCCESS,
                success: "AddMemberToGroup"
            }); 
            getGroupMems(params.get('groupGuid'));  
            dispatch({
                type: T.REMOVE_LOADING
            })        
        }).catch(e=>{
            dispatch({
                type: T.CREATE_GROUP_FAILURE,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.CREATE_GROUP_FAILURE,
            payload: e
        })
    }
 }
 export const DeleteGroup = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: T.ADD_LOADING})

        postRequest(`${api_domian}/teams/RemoveGroup`, params)
       .then((resp: any)=>{
            dispatch({
                type: T.SAVE_SUCCESS,
                success: "RemoveGroup"
            });
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({type: T.SET_ERRORS,payload: e});
        });
    } catch (e) {
        dispatch({type: T.SET_ERRORS,payload: e});
    }
 }

 
 export const createTeamsMeetingLink = async (accessToken: string, teamId: string) => {
    try {
       return getRequest(`${api_domian}/Teams/CreateMeetingWithChannel?accesstoken=${accessToken}&teamId=${teamId}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
            console.log('error in creating link', e);
            return "error";
        });
    } catch (e) {
        console.log('error in creating link' , e);
        return "error";
    }
 }
 
  /*** Get Group Details GroupId */
  export const getGroupDetailsById = async (grupId: string,empGuid:string,email:string)=>{ 
    try {
       return getRequest(`${api_domian}/Teams/GetGroupDetailsByGroupsId?grpGuid=${grupId}&empGuid=${empGuid}&mail=${email}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
            console.log(e);
            return "ERROR";
        });
    } catch (e) {
        return "ERROR";
    }
 }
export const updateRtmStatus = (statusAr: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: T.AGORA_USER_STATUS,
        payload: statusAr
    });
}
 /**getUserStatusess, usrIds is comma separated empGuids */
 export const getUserStatusess = (usrIds: any) => async (dispatch: Dispatch) => {
    getRequest(`${api_domian}/employee/GetEmployeeStatusDetailsByEmpList?empIdLst=${usrIds}`)
    .then((resp: any)=>{
        let statusAr = {};
        const r = Array.isArray(resp) ? resp : resp.result;
        r.map((us:any)=>{
            statusAr = {...statusAr, [us.empGuid]:(us.status || "Available")};
        });
        dispatch({
            type: T.SUTE_USER_STATUS,
            payload: statusAr
        });
        
    }).catch(e=>{
        dispatch({type: T.SET_ERRORS,payload: e});
    });
 }

 export const checkUserInCall = async ( empguid: string) => {
    try {
       return getRequest(`${api_domian}/Teams/CheckEmployeeCallStatus?empGuid=${empguid}`)
       .then((resp: any)=>{
            return resp;
        }).catch(e=>{
            console.log('error in creating link', e);
            return "error";
        });
    } catch (e) {
        console.log('error in creating link' , e);
        return "error";
    }
 }

 export const GetIP = () => async (dispatch: Dispatch) => {
    try {
        getRequest("https://ipapi.co/json/")
       .then( async (resp: any)=>{       
            dispatch({
                type: T.GET_IP_ADRESS,
                payload: resp.ip
            });
           
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: 'IPERROR'
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: 'IPERROR'
        })
    }
 }

//  export const getIPAddress =  () => {
//     const config = {
//         headers: {
//           "Access-Control-Allow-Origin": "*",
//           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
//         }
//       };
      
//        axios.get("https://api.ipify.org?format=json", config).then((response) => {
//     console.log(" getIPAddress ",response)
//     });
//  }
