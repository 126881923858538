import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { t } from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../../store/Store';
import { DeleteGuestMessage, DeleteMessage } from '../../services/ChatServices';
import FileIcon from '../../components/FileIcon';
import ScrollToBottom from 'react-scroll-to-bottom';
//import { suteClient } from '../../services/sute-rtc-api';
const api_domian = process.env.REACT_APP_API_HOST as string;

function formatDateTime(dateTime: string | undefined) {
    if (dateTime !== undefined) {
        const tm = parseInt(dateTime, 10);
        return moment(tm).format('hh:mm a');
    }
}
function fullDate(dateTime: string | undefined) {
    if (dateTime !== undefined) {
        const tm = parseInt(dateTime, 10);
        return moment(tm).format('DD MMM, YYYY');
    }
    return "-";
}
function FilesPreview(props: any) {
    const isImage = React.useRef(false);
    const [fileType, setFileType] = React.useState('');
    useEffect(() => {
        if (props.data.filename.match(/.(jpg|jpeg|png|gif)$/i)) {
            isImage.current = true;
        } else {
            const ft = props.data.filename.split('.').pop().toUpperCase();
            console.log('file type', ft);
            setFileType(ft);
        }
    }, []);
    const downloadFile = () => {
        window.location.href = `${api_domian}/file/DownloadFile?FileGuid=${props.data.fileId}`;
    }
    return (<>
        {!isImage.current ? <><a target="_blank" href={`${api_domian}/file/DownloadFile?FileGuid=${props.data.fileId}`}>
            <FileIcon name={fileType} />
            <p className="mb-0 mt-1" title="Click to download">
                {props.data.filename}</p></a></> :
            <img src={props.data.url} />
        }
    </>)
}
function ChatTranscrit(props: any) {
    const messageRef = React.createRef<HTMLUListElement>();
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const history = useNavigate();
    const dispatch = useDispatch();
    const empId = localStorage.getItem('EmpGuid') || '';
    const GuestName = localStorage.getItem('GuestName') || '';
    const getUserDisplayName = (frmUsrId: any) => {
        if (empId === frmUsrId)
            return 'me'
        console.log("members", props.members);

        if (!props.members)
            return "loading";
        let cusr = [];
        if (Array.isArray(props.members))
            cusr = props.members.filter((usr: any) => usr.EmpGuid === frmUsrId);
        else if (Array.isArray(props.members.result))
            cusr = props.members.result.filter((usr: any) => usr.EmpGuid === frmUsrId);

        return cusr.length > 0 ? cusr[0].Name || cusr[0].FirstName : 'No Name';
    }
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end'
                });
        }
    }, [user.newMsgsCount, props.scrollEnd]);

    const ScrollMsgs = (e: any) => {
        //console.log(e.target.scrollTop);
        if (e.target.scrollTop === 0 && props.messages.length > 0) {
          //  props.getmore(props.messages[0].TimeStamp);
        }
    }
    const joinmeeting = (callId: string) => {
        localStorage.setItem('newMeetingType', callId);
        localStorage.setItem('newMeetingid', callId);
        history('/customrm/' + callId);
    }

    const deleteMsg = (msgId: string) => {
        console.log('delete msg', msgId, props.peerId)
        if (user.isAuthenticated)
            dispatch(DeleteMessage(empId, msgId, props.peerId) as any);
        else
            dispatch(DeleteGuestMessage(props.uid, msgId, props.peerId) as any);

    }
    const replyMsg = (msgId: string) => {
        //
    }
    const forwardMsg = (msg: string) => {
        //
    }
    const unicodeToChar = (text: any) => {
        return String.fromCodePoint(parseInt(text, 16));
    };


    const getIcon = (name: string) => {
        let color = '#50BEE8'

        if (name == 'PDF' || name == 'PPT' || name == 'PPTX')
            color = '#fd6b6b';
        if (name == 'MPG' || name == 'MPEG' || name == 'MP3')
            color = '#f7bc50';
        if (name == 'CSV' || name == 'XLSX')
            color = "#84BD5A";
        return `<div style="width: 100px;"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><path d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" style="fill: rgb(226, 229, 231);"></path><path d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" style="fill: rgb(176, 183, 189);"></path><polygon points="480,224 384,128 480,128 " style="fill: rgb(202, 209, 216);"></polygon><path d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
      V416z" style="fill:`+ color + `;"></path><g><text x="50" y="360" font-family="Epilogue, sans-serif" font-size="950%" fill="white">` + name + `</text></g><path d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" style="fill: rgb(202, 209, 216);"></path></svg></div>`
    }
    const setEmoji = (newText: string) => {
        let unicode = newText;
        let orgtext = '';
        let len = -1;
        while (unicode) {
            len = unicode.indexOf('_EMOJ_');
            if (len > -1) {
                orgtext = orgtext + unicode.substring(0, len);
                unicode = unicode.substring(len + 6);
                len = unicode.indexOf('_EMOJ_');
                orgtext = orgtext + unicodeToChar(unicode.substring(0, len));
                unicode = unicode.substring(len + 6);
            }
            else {
                orgtext = orgtext + unicode;
                break;
            }
        }
        return orgtext;
    }

    const setMedia = (newText: any) => {
        let url = getMedia(newText)
        let ext = url.substring(url.lastIndexOf('/'));
        ext = ext.substring(0, ext.lastIndexOf('?'))

        let val = ''
        let str = ''
        if (ext) {
            val = ext.substring(ext.indexOf('.') + 1).toUpperCase();
        }
        if (val == 'JPG' || val == 'PNG' || val == 'JPEG' || val == 'GIF') {
            str = `<img width="100px" height="100px" src=` + url + ` />`;

        } else {
            str = getIcon(val);
        }

        return str;

    }
    const getMedia = (newText: string) => {
        let unicode = newText;
        let orgtext = '';
        let len = -1;
        while (unicode) {
            len = unicode.indexOf('_MEDIA_');
            if (len > -1) {
                orgtext = orgtext + unicode.substring(0, len);
                unicode = unicode.substring(len + 7);
                len = unicode.indexOf('_MEDIA_');
                // orgtext = orgtext + setMedia(unicode);
                orgtext = orgtext + unicode.substring(0, len);
                //orgtext = orgtext + getIcon('PDF');
                unicode = unicode.substring(len + 7);

            }
            else {
                orgtext = orgtext + unicode;
                break;
            }

        }

        return orgtext;

    }

    const setLink = (newText: string) => {
        let unicode = newText;
        let orgtext = '';
        let len = -1;
        while (unicode) {
            len = unicode.indexOf('_LINK_');
            if (len > -1) {
                orgtext = orgtext + unicode.substring(0, len);
                unicode = unicode.substring(len + 6);
                len = unicode.indexOf('_LINK_');
                orgtext = orgtext + unicode.substring(0, len);
                unicode = unicode.substring(len + 6);

            }
            else {
                orgtext = orgtext + unicode;
                break;
            }
        }
        return `<a target='_blank' href="${orgtext}">${orgtext}</a>`;
    }
    const getText = (id: string, newText: string) => {
        let orgtext = newText;
        let unicode = newText;
        let len = -1;
        len = unicode.indexOf('_EMOJ_');
        if (len > -1) {
            orgtext = setEmoji(unicode);
        }
        len = unicode.indexOf('_MEDIA_');   
        if (len > -1) {
            orgtext = setMedia(unicode);
        }
        len = unicode.indexOf('_LINK_');
        if (len > -1) {
            orgtext = setLink(unicode);
        }
        let ele = document.getElementById(id);

        if (ele)
            ele.innerHTML = orgtext;
        // return orgtext;
    }

    return (
        <ScrollToBottom className="chattingPanel" checkInterval={20}>
        <div >
            {props.messages && props.messages.length > 0 ? <><ul>
                {props.messages && props.messages.map(
                    function (msg: any) {
                        if (msg.IsDeleted)
                            return '';
                        msg.text = msg.text || msg.Message;
                        let msgJson: any = {};
                        if (msg.text && msg.text.indexOf('UUID') > -1) {
                            msgJson = JSON.parse(msg.text);
                        } else {
                            msgJson.text = msg.text;
                        }
                        if (!msg.SenderName) {
                            msg.SenderName = getUserDisplayName(msg.SenderGuid);
                        }
                        
                        if (msg.SenderGuid === '00000000-0000-0000-0000-000000000000') {
                            console.log('#SENDER',msg.SenderName,props.userName);
                            if (msg.SenderName == props.userName)
                                msg.SenderName = 'me'
                            
                           // else 
                            if (msg.SenderName !== 'me')
                                msg.SenderName = msg.SenderName.indexOf(' (Guest)') > -1 ? msg.SenderName : msg.SenderName + ' (Guest)'
                        }
                        else
                            if (msg.SenderGuid === empId) {
                                msg.SenderName = 'me'
                            }
                        if (msgJson && msgJson.text.length > 0 && msgJson.text.indexOf('__ZCALL__|') > -1) {
                            const callId = msgJson.text.split('|')[1];
                            return (<li key={msg.TimeStamp} className={msg.SenderGuid === 'me' ? 'messageFromMe' : ''}>
                                <div className="ConvSenderName">{msg.SenderGuid === empId ? "me" : msg.SenderName} <span>{formatDateTime(msg.TimeStamp)}</span></div>
                                Zoom meeting created, Click join now below
                                <div className="callmsg"><a target="_blank" className="waves-effect waves-light" href={callId}>
                                    <i className="mdi mdi-google-classroom"></i>
                                    <span> Join Now</span></a></div></li>)
                        } else if (msgJson.text.length > 0 && msgJson.text.indexOf('__MSCALL__|') > -1) {
                            const callId = msgJson.text.split('|')[1];
                            return (<li className={msg.from === 'me' ? "FromSelf" : "ml25"} key={msg.TimeStamp}>
                                <div className="chattingUserName">
                                    <h4 className="float-left m-0">{msg.SenderGuid === empId ? "me" : msg.SenderName}</h4>
                                    <p className="float-right">{formatDateTime(msg.TimeStamp)}</p>
                                </div>
                                <div className="chattingContent"><p>MS teams meeting created, Click join now below <a target="_blank" className="waves-effect waves-light" href={callId}>
                                    <i className="mdi mdi-google-classroom"></i>
                                    <span> Join Now</span></a></p>
                                </div>
                            </li>)
                        } else if (msgJson.text.length > 0 && msgJson.text.indexOf('__CALL__|') > -1) {
                            const callId = msgJson.text.split('|')[1];
                            return (<li className={msg.SenderName === 'me' ? "FromSelf" : "ml25"} key={msg.TimeStamp}>
                                <div className="chattingUserName">
                                    <h4 className="float-left m-0">{msg.SenderGuid === empId ? "me" : msg.SenderName}</h4>
                                    <p className="float-right">{formatDateTime(msg.TimeStamp)}</p>
                                </div>
                                <div className="chattingContent"><p>{t('chattranscrit.meeting_is_started')} <button className="btn btn-primary btn-sm" onClick={() => joinmeeting(callId)}>{t('button.join_now')}</button></p>
                                </div>
                            </li>)
                        } else if (msgJson.text.length > 0 && msgJson.text.indexOf('__CALL__') > -1) {
                            //no comments
                        } else {
                            return (
                                <li className={msg.SenderName === "me" ? "FromSelf" : "ml25"} key={msg.UUID} >
                                    {(msg.SenderName !== 'me') && (msgJson.text.length > 0 && msgJson.text.indexOf('_MEDIA_') > -1) ? <span className="chattingActions" role="button" id="chatActiondd" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 3a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1zm0 5a.5.5 0 110-1 .5.5 0 010 1z" stroke="#f1f1f1"></path></svg></span> : ''}
                                    <div className="dropdown-menu dropdown-menu-right fs12" aria-labelledby="chatActiondd">

                                        {/* {msg.SenderName === 'me' ? <a onClick={() => { deleteMsg(msg.UUID) }} className="dropdown-item c-pointer">
                                            <svg viewBox="0 0 15 15" fill="none" width="12" height="12"><path fillRule="evenodd" clipRule="evenodd" d="M11 3V1.5A1.5 1.5 0 009.5 0h-4A1.5 1.5 0 004 1.5V3H0v1h1v9.5A1.5 1.5 0 002.5 15h10a1.5 1.5 0 001.5-1.5V4h1V3h-4zM5 1.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V3H5V1.5zM7 7v5h1V7H7zm-3 5V9h1v3H4zm6-3v3h1V9h-1z" fill="currentColor"></path></svg> {t('chattranscrit.delete')}</a>
                                            : ''
                                        } */}
                                        {/* <a onClick={() => { replyMsg(msg.UUID) }} className="dropdown-item c-pointer">
                                            <svg viewBox="0 0 15 15" fill="none" width="12" height="12"><path d="M2.5 1A1.5 1.5 0 001 2.5V10a4 4 0 004 4v-1a3 3 0 01-3-3V7h3.5A1.5 1.5 0 007 5.5v-3A1.5 1.5 0 005.5 1h-3zm7 0A1.5 1.5 0 008 2.5V10a4 4 0 004 4v-1a3 3 0 01-3-3V7h3.5A1.5 1.5 0 0014 5.5v-3A1.5 1.5 0 0012.5 1h-3z" fill="currentColor"></path></svg> {t('chattranscrit.reply')}</a> */}
                                        {(msgJson.text.length > 0 && msgJson.text.indexOf('_MEDIA_') > -1) ? <a target='_blank' href={getMedia(msgJson.text)} className="dropdown-item c-pointer">
                                            <svg viewBox="0 0 15 15" fill="none" width="12" height="12"><path d="M14.954.71a.5.5 0 01-.1.144L5.4 10.306l2.67 4.451a.5.5 0 00.889-.06L14.954.71zM4.694 9.6L.243 6.928a.5.5 0 01.06-.889L14.293.045a.5.5 0 00-.146.101L4.694 9.6z" fill="currentColor"></path></svg> Download</a>
                                            : ''
                                        }
                                    </div>
                                    <div className="chattingUserName">
                                        <h4 className="float-left m-0">{msg.SenderName}</h4>
                                        <p className="float-right" title={fullDate(msg.TimeStamp)}>{formatDateTime(msg.TimeStamp)}</p>
                                    </div>
                                    <div className="chattingContent">
                                        {msgJson.files && msgJson.files.length > 0 && msgJson.files.map((file: any) => {
                                            return <FilesPreview data={file} />
                                        })
                                        }
                                        <p id={msg.UUID}> </p>
                                        {getText(msg.UUID, msgJson.text)}

                                    </div>
                                </li>
                            )
                        }
                    }
                )}
            </ul></> : ''}
            <br />
            {props.messages && props.messages.length > 0 ? '' : <div className="m-auto text-center text-light"><img className="w-50" src="/assets/media/svg/happyHourNA.svg" /><br /><p className="mt-2">{t('chattranscrit.no_messages')}</p></div>}
            {props.typingUser ? <div className="typingIndicator">{props.typingUser} is typing <span>.</span><span>.</span><span>.</span><i className="mdi mdi-pencil"></i></div> : ''}
            
        </div></ScrollToBottom>
    );
}

export default ChatTranscrit;